body {
	position: relative;
	margin: 0;
	top: 0;
	right: 0;
	left: auto;
	padding: 0;
	direction: rtl;
	height: "100%";
}
